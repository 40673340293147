import React from "react"
import Index from "@pages/mt4-mobile.js";

const Mt4MobileJa = () => {
  const setLang = 'ja';
  return (
  <><Index langType={setLang}/></>
  )
}
export default Mt4MobileJa
